<style lang="scss" scoped>
@import "assets/scss/default.scss";
</style>

<template>
	<Header></Header>
	<div id="wrapper">
		<Navbar :navLinks="navLinks"/>
		<main v-auto-animate>
			<UContainer :ui="ui">
				<slot/>
			</UContainer>
		</main>
	</div>
</template>

<script setup>
const ui = {
	base: "h-full",
	padding: "",
	constrained: ""
};

const navLinks = [
	{ url: "/management", name: "Administration", icon: "isax isax-element-4" },
	{ url: "/management/accounts", name: "Comptes client", icon: "isax isax-buildings4" },
	{ url: "/management/orders", name: "Commandes", icon: "isax isax-box" },
  { url: "/management/config", name: "Configurations", icon: "isax isax-code-1" }
];
</script>

<script>


export default {
	name: "ManagementLayout"
};
</script>
